import styled from "styled-components"
import qr from './assets/images/qr.svg';
import appStoreImage from './assets/images/appStore.svg';
import googlePlayImage from './assets/images/googlePlay.svg';
const ContainerTest = styled.div`
    max-width: calc(100vw - 20px);
    margin-bottom: 140px;
    width: 100%;
    margin-inline: auto;
    @media (max-width: 768px) {
      margin-bottom: 30px !important;
    }
  `;
export default function StartComponent ({ExtraTitle,primary,ConsultButton, Text}) {

const Container_ = styled(ContainerTest)`
display: flex;
justify-content: space-between;
padding-right: 80px;
gap: 50px;
@media (max-width: 768px){
flex-direction: column;
padding-right: 0!important;
}


`

const Wrapper = styled.div`
display: flex;
flex-direction: column;
max-width: 518px;
position: relative;
`
const Title = styled(ExtraTitle)`
margin-bottom: 20px;
color: ${primary};
`;

const RightImageCollapse = styled.div`
display: flex;
flex-direction: column;
gap: 15px;


`;
const Text_ = styled(Text)`
@media (max-width: 768px){
margin-bottom: 20px!important;

}
`
const QrImage = styled.div`
width: 312px;
height: 312px;
background: url(${qr});
background-size: cover;
@media (max-width: 768px){
display: none;}
`
const RightImageCollapseBottom = styled.div`
display: flex;
justify-content: space-between;
width: 100%;
@media (max-width: 768px){
gap: 12px;
justify-content: left;
}
`
const RightImageCollapseBottomImage = styled.a`
    display: block;
width: 153px;
height: 44px;
background: url(${props=>props.src});
background-size: cover;
`;
    return (
        <>
        <Container_>
            <Wrapper>
            <Title>Начните учиться
            в ПДД.ТВ сейчас</Title>
            <Text_>Мы сделали официальное приложение для изучения теории. Ты сможешь попробовать нашу методику обучения в удобном для себя формате и без затрат!</Text_>
            <ConsultButton as='a' href='#consult'>Нужна помощь → </ConsultButton>
            </Wrapper>
            <RightImageCollapse>
<QrImage />
<RightImageCollapseBottom>

<RightImageCollapseBottomImage src={appStoreImage} href='https://apps.apple.com/ru/app/%D0%BF%D0%B4%D0%B4-%D1%82%D0%B2/id1597304100' target='_blank' />
<RightImageCollapseBottomImage src={googlePlayImage}  href='https://www.rustore.ru/catalog/app/ru.svetofor.app' target='_blank'  />
</RightImageCollapseBottom>
            </RightImageCollapse>
        </Container_>

        </>
    )
}
